import { NoteInfo } from './types'

export const editNote = async <T extends Record<string, unknown>>(
	note: NoteInfo<T>,
	options?: {
		generateTitle?: boolean
		generateSummary?: boolean
		translate?: boolean
	},
): Promise<void> => {
	console.log('editNote', note, options)

	// // Check if anything needs to be generated before opening the edit form
	// if (options?.generateTitle || options?.generateSummary || options?.translate) {
	// 	const res = await generate
	// }

	// openFlyoutForm({
	// 	title: 'Edit Note',
	// 	form: <></>,
	// })
	// return <>TODO</>
}
