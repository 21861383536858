import { Alerts } from '../../component-main'
import { NotesComponentProps } from './types'

export const deleteNote = <T extends Record<string, unknown>>(
	props: NotesComponentProps<T>,
	noteID: string,
) => {
	Alerts.Confirm({
		msg: 'Are you sure you want to mark this note as deleted?',
		yes: () => {
			// Send request to server to remove this leave
			props.deleting?.onDelete(noteID)
		},
	})
}
