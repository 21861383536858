import { BuildClass, Maybe } from '../../../../universal'
import { React } from '../../../lib'
import { CustomPDFContainer } from '../../component-pdf-viewer'
import { setContextMenu } from '../context-menu'
import { HelpTooltip } from '../help'
import { KebabMenu } from '../kebab'
import { CJSX } from '../meta-types'
import { TextSearched } from '../text'
import { getNoteActions } from './actions'
import { generateContent } from './generative'
import { ACTION_CONTEXT_MENU_WIDTH, NoteInfo, ReducerState } from './types'

export const NoteDetails = <T extends Record<string, unknown>>(props: {
	rs: ReducerState<T>
}): React.JSX.Element => {
	const rs = props.rs

	// Get the details of the currently-selected note
	const selectedNote = rs.props.notes.find(x => x.ID == rs.state.selectedNote)
	const hasAttachment = Boolean(selectedNote?.Attachment?.Checksum)

	// If no note is selected
	if (!selectedNote) {
		return (
			<div className="h-full overflow-y-auto px-2 bg-neutral-300 relative">
				<span
					className={BuildClass([
						'absolute top-1/3 left-1/2 translate-x-[-50%] translate-y-[-50%]',
						'font-light text-xl text-neutral-600 text-center',
					])}
				>
					Select a note
				</span>
			</div>
		)
	}

	// Render
	return (
		<div className="relative h-full overflow-y-auto bg-neutral-300 flex flex-col">
			{/* Custom link above the note - for linking to entities */}
			{selectedNote.Meta ? (
				<div
					className={BuildClass([
						'p-2 m-1 bg-white',
						'border border-solid border-neutral-400 rounded-md',
						'font-condensed select-none text-center',
					])}
				>
					<CJSX cond={Boolean(selectedNote.Meta.link)}>
						<a
							className="text-blue-500 hover:text-red-600 cursor-pointer"
							title={selectedNote.Meta.text}
							onClick={e => {
								e.preventDefault()
								selectedNote.Meta.link?.()
							}}
						>
							<TextSearched
								text={selectedNote.Meta.text}
								needles={rs.state.searchText}
								tailwind={true}
							/>
						</a>
					</CJSX>
					<CJSX cond={!Boolean(selectedNote.Meta.link)}>
						<TextSearched
							text={selectedNote.Meta.text}
							needles={rs.state.searchText}
							tailwind={true}
						/>
					</CJSX>
				</div>
			) : (
				<></>
			)}

			{/* Overlay for the kebab menu icon */}
			<div
				className={BuildClass({
					'absolute right-[5px] w-8 h-8': true,
					'top-[5px]': !Boolean(selectedNote.Meta),
					'top-[50px]': Boolean(selectedNote.Meta),
				})}
			>
				<KebabMenu
					className={{
						div: [
							'w-full h-full p-2 rounded-md',
							'cursor-pointer hover:bg-[hsla(0,0%,0%,0.1)]',
						],
						img: 'w-4 h-4',
					}}
					preferLeft={true}
					stopPropagation={true}
					width={ACTION_CONTEXT_MENU_WIDTH}
					items={() => getNoteActions(rs, selectedNote)}
				/>
			</div>

			{/* Main note section */}
			<pre
				className={BuildClass({
					'p-2 m-1 bg-white': true,
					'border border-solid border-neutral-400 rounded-md': true,
					'font-condensed whitespace-pre-wrap select-text': true,
					'overflow-y-auto': true,
					'min-h-9 max-h-[30%]': hasAttachment,
				})}
				onContextMenu={e => {
					e.preventDefault()
					e.stopPropagation()
					setContextMenu({
						position: {
							x: e.clientX,
							y: e.clientY,
						},
						width: ACTION_CONTEXT_MENU_WIDTH,
						items: () => getNoteActions(rs, selectedNote),
					})
				}}
			>
				{/* Title */}
				<h2
					className={BuildClass([
						'mb-3 pr-6',
						'font-bold font-condensed text-xl leading-7 text-black',
					])}
				>
					<CJSX cond={Boolean(selectedNote.Title)}>
						<TextSearched
							text={selectedNote?.Title ?? ''}
							needles={rs.state.searchText}
							tailwind={true}
						/>
					</CJSX>
					<CJSX cond={!selectedNote.Title}>
						<span className="text-neutral-500 font-normal">
							No Title
							<HelpTooltip title="Use generative AI to quickly produce a title based on the note contents">
								<button
									className="ml-2 grayscale hover:grayscale-0"
									onClick={() => {
										generateContent(rs, selectedNote, ['title'])
									}}
								>
									<img
										src="/static/img/i8/color-sparkles.svg"
										className="w-4 h-4"
										alt="Generative AI"
									/>
								</button>
							</HelpTooltip>
						</span>
					</CJSX>
				</h2>

				{/* Body */}
				<CJSX cond={!selectedNote.Note}>
					<span className="text-neutral-500">
						No body text
						<HelpTooltip title="Use generative AI to quickly produce a note based on the attachment">
							<button
								className="ml-2 opacity-70 hover:opacity-100"
								onClick={() => {
									generateContent(rs, selectedNote, ['summary'])
								}}
							>
								<img
									src="/static/img/i8/color-sparkles.svg"
									className="w-4 h-4"
									alt="Generative AI"
								/>
							</button>
						</HelpTooltip>
					</span>
				</CJSX>
				<TextSearched
					text={selectedNote?.Note?.trim() ?? ''}
					needles={rs.state.searchText}
					tailwind={true}
				/>
			</pre>

			{/* Attachment */}
			<CJSX cond={hasAttachment}>
				<div className="flex-1 overflow-y-auto">
					<NoteAttachment rs={rs} selectedNote={selectedNote} />
				</div>
			</CJSX>
		</div>
	)
}

const NoteAttachment = <T extends Record<string, unknown>>(props: {
	rs: ReducerState<T>
	selectedNote: NoteInfo<T>
}) => {
	// If there's no attachment
	const attachment = props.selectedNote.Attachment
	if (!attachment || !attachment.Checksum) {
		return <></>
	}

	// Get the URL
	const url = `https://storage.trionline.com.au/get/${attachment.Checksum}`

	// Embed an inline version of image, video, audio, or PDF
	if (image_mimes.includes(attachment.MimeType)) {
		return <img src={url} />
	}
	if (video_mimes.includes(attachment.MimeType)) {
		return <video src={url} />
	}
	if (audio_mimes.includes(attachment.MimeType)) {
		return <audio src={url} />
	}
	if (pdf_mimes.includes(attachment.MimeType)) {
		return <CustomPDFContainer url={url} />
	}

	// Display a link to download the attachment
	// Put the file extension in there
	const ext_key = attachment.MimeType as keyof typeof misc_file_type_extensions
	const ext: Maybe<string> = misc_file_type_extensions[ext_key] ?? null
	return (
		<a href={url} target="_blank">
			{ext ? `attachment.${ext}` : 'attachment'}
		</a>
	)
}

const image_mimes = [
	'image/bmp',
	'image/gif',
	'image/jpeg',
	'image/png',
	'image/webp',
	'image/x-icon',
]

const pdf_mimes = ['application/pdf']

const video_mimes = ['video/mp4', 'video/webm']

const audio_mimes = ['audio/mpeg', 'audio/wav', 'audio/ogg', 'audio/webm']

const misc_file_type_extensions = {
	'application/octet-stream': 'bin',
	'application/postscript': 'ps',
	'application/vnd.ms-fontobject': 'eot',
	'application/x-gzip': 'gz',
	'application/x-rar-compressed': 'rar',
	'application/zip': 'zip',
	'font/ttf': 'ttf',
	'font/woff': 'woff',
	'text/html; charset=utf-8': 'html',
	'text/plain; charset=utf-16le': 'txt',
	'text/plain; charset=utf-8': 'txt',
	'text/xml; charset=utf-8': 'xml',
}
