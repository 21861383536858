import { Alerts } from '../..'
import { svrtsRequest } from '../../svrts-interface'
import { NoteInfo, ReducerState } from './types'

export const generateMissingContent = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
	notes: NoteInfo<T>[],
) => {
	rs.updateState({ loadingCoverMessage: 'Generating...' })
	svrtsRequest({
		data: {
			funcID: [0, 62],
			notes: notes.map(note => ({
				id: note.ID,
				title: note.Title,
				note: note.Note,
				attachment: note.Attachment?.Checksum,
				generateTitle: !note.Title,
				generateNote: !note.Note,
				generateTranslation: !note.Note,
			})),
		},
		any: () => {
			rs.updateState({ loadingCoverMessage: null })
		},
		no: data => {
			Alerts.Alert({ msg: data.message })
		},
		yes: data => {
			console.log(data)
		},
	})
}

export const generateContent = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
	note: NoteInfo<T>,
	types: ('title' | 'summary')[],
) => {
	// TODO - implement this
	console.log('TODO: Generate content', rs, note, types)
}
