import { Maybe, tDate } from '../../../../universal'
import { React } from '../../../lib'
import { ListGridInstance } from '../list-grid'
import { Focusable, RSInstanceD } from '../meta-types'

export const ACTION_CONTEXT_MENU_WIDTH = 170

export type FileAttachment = {
	Checksum: string
	MimeType: string
}

export type NoteInfo<T extends Record<string, unknown>> = {
	ID: string
	Title: Maybe<string>
	Note: string
	Attachment: Maybe<FileAttachment>
	Created: tDate
	CreatedAuthor: Maybe<number>
	LastEdited: tDate
	LastEditedAuthor: Maybe<number>
	ExtraInfo: T
	Deleted: boolean
	Meta: Maybe<{
		text: string
		link: Maybe<() => void>
	}>
}

export type NoteRevisionHistorical = {
	NoteID: string
	Created: tDate
	Author: number
	Title: Maybe<string>
	Note: string
	Attachment: Maybe<FileAttachment>
}

export type NoteTag = {
	ID: string
	DisplayName: string
	Description: string
}

export type NoteTagGroup = {
	GroupName: string
	Tags: NoteTag[]
	IsCompulsory: boolean
}

export type NotesComponentProps<T extends Record<string, unknown>> = {
	/** Class name to add to the DOM - can be used for tailwind styles */
	className?: string
	/** The array of note data */
	notes: NoteInfo<T>[]
	/** The array of note categories */
	categories: (NoteTag | NoteTagGroup)[]
	/** The serialised, controllable state */
	state?: {
		/** The selected note */
		selectedNote: Maybe<string>
		/** Search text */
		searchText: string
		/** Whether to show deleted notes */
		showDeleted: boolean
		/** The sizes of the resizable panes */
		paneSizes: Maybe<Maybe<number>[]>
		/** Event when the selected note or search text updates */
		onUpdate: (delta: {
			selectedNote: Maybe<string>
			searchText: string
			paneSizes: Maybe<Maybe<number>[]>
		}) => void
	}
	/** Details required to allow adding new notes. Null means adding isn't allowed */
	adding: null | {
		/** The title to display when adding a new note */
		formTitle: string
		/** The prompt to use for the form component when adding a new note */
		formPrompt: string
		/** The voice prompt to use for the form component when adding a new note */
		voicePrompt: string
		/** The function to call when the user is adding a new note */
		onAdd: (input: {
			note: {
				Title: string
				Note: string
				Tags: string[]
				Attachment: Maybe<string>
			}
			/** Callback function - first param is the new ID, second is the result of the operation */
			onComplete: (newID: Maybe<string>, successOrError: true | string) => void
		}) => void
	}
	/** Details required to allow editing existing notes. Null means editing isn't allowed */
	editing: null | {
		/** The title to display when editing a note */
		formTitle: string
		/** The prompt to use for the form component when editing a note */
		formPrompt: string
		/** The function to call when the user is editing a note */
		onEdit: (input: {
			note: NoteInfo<T>
			onComplete: (successOrError: true | string) => void
		}) => void
	}
	/** Details required to allow deleting notes. Null means deleting isn't allowed */
	deleting: null | {
		/** The function to call when the user is deleting a note */
		onDelete: (noteID: string) => void
	}
	/** Given to the language model to tailor the title/summary to the specific usage */
	summaryContext?: {
		/** The prompt to use for the title field - summarises a note/attachment */
		title?: string
		/** The prompt to use for the note field - summarises an attachment if present */
		note?: string
	}
	/** Information about managing edit history. Null means edit histories aren't stored */
	history: null | {
		/** The function to call to get the history of notes */
		fetchOldRevisions: (noteIDs: string[]) => Promise<NoteRevisionHistorical[]>
	}
}

export type NotesComponentState = {
	searchText: string
	showDeleted: boolean
	selectedNote: Maybe<string>
	paneSizes: Maybe<Maybe<number>[]>
	loadingCoverMessage: Maybe<string>
}

export type NotesComponentRefs<T extends Record<string, unknown>> = {
	grid: React.RefObject<ListGridInstance<NoteInfo<T>, false>>
	searchBox: React.RefObject<Focusable<HTMLInputElement>>
}

export type ReducerState<T extends Record<string, unknown>> = RSInstanceD<
	NotesComponentProps<T>,
	NotesComponentState,
	NotesComponentRefs<T>,
	object
>
