import { FormType, openFlyoutForm } from '../form'
import { ReducerState } from './types'

export const addNewNoteForm = <T extends Record<string, unknown>>(
	rs: ReducerState<T>,
) => {
	if (!rs.props.adding) {
		return
	}
	openFlyoutForm({
		title: rs.props.adding.formTitle,
		size: [400, null],
		lblWidth: 38,
		formPrompt: rs.props.adding.formPrompt,
		fields: {
			title: FormType.Text({
				lbl: 'Title',
				placeholder: '(Optional)...',
				maxLength: 80,
			}),
			tags: FormType.DropdownMultiStringKey({
				lbl: 'Tags',
				tooltip:
					'Select one or more tags to help categorise the note. If left empty, the system will apply tentative tags for you to approve based on the content.',
				placeholder: '(Optional)',
				options: rs.props.categories.map(x =>
					'Tags' in x
						? {
								selectable: false,
								text: x.GroupName,
								options: x.Tags.map(subTag => ({
									value: subTag.ID,
									text: subTag.DisplayName,
									tooltip: subTag.Description,
								})),
							}
						: {
								value: x.ID,
								text: x.DisplayName,
								tooltip: x.Description,
							},
				),
			}),
			note: FormType.Textarea({
				lbl: 'Note',
				fullWidth: true,
				voicePrompt: rs.props.adding.voicePrompt,
				placeholder: 'Add your note here...',
				maxLength: 65535,
				rows: 8,
			}),
			file: FormType.FileUploadSingle({
				lbl: 'Attachment',
				dragText: 'Optional file attachment',
				fullWidth: true,
				height: 64,
			}),
		},
		onSave: (model, formCallback) => {
			// Run the application-level function to add the note
			// This is implemented for each place using the notes component
			rs.props.adding?.onAdd({
				note: {
					Title: model.title,
					Note: model.note,
					Tags: model.tags,
					Attachment: model.file,
				},
				onComplete: (newID, result) => {
					// Send result to form component to show tick/error
					formCallback(result)
					// If there's a new ID, update the state to select it
					if (newID) {
						rs.updateState({
							selectedNote: newID,
						})
					}
				},
			})
		},
	})
}
